import { ACTION_TYPE } from './useGetUserAcceptanceTOU';

const initialState = {
  data: {},
  loading: false,
  error: false,
};

export const reducerGetUserAcceptanceTOU = {
  [ACTION_TYPE]: (state = initialState, action) => {
    switch (action.type) {
      case `${ACTION_TYPE}_CALLED`:
        return {
          ...state,
          loading: true,
          error: false,
        };
      case `${ACTION_TYPE}_COMPLETED`:
        return {
          ...state,
          data: action.payload,
          loading: false,
          error: false,
        };
      case `${ACTION_TYPE}_ERROR`:
        return {
          ...state,
          loading: false,
          data: action.payload,
          error: true,
        };
      case `${ACTION_TYPE}_RESET`:
        return {
          ...initialState,
        };
      default:
        return state;
    }
  },
};
