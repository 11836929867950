import { reducerUserInfo } from 'src/hooks/useUserInfo';
import { reducerUserStatus } from 'src/hooks/useUserStatus';
import { reducerGitHubUser } from 'src/hooks/useGitHubUser';
import { reducerGitHubRepos } from 'src/hooks/useGitHubRepos';
import { reducerBreadcrumbs } from 'src/hooks/useBreadcrumbs';
import { reducerClaimSummary } from 'src/hooks/useClaimSummary';
import { reducerRegenerateSecret } from 'src/hooks/useRegenerateSecret';
import { reducerDocumentation } from '../../hooks/useDocumentation';
import { reducerFeedbackSubmission } from '../../hooks/useFeedbackSubmission/reducerFeedbackSubmission';
import { reducerLeadGenerationApi } from '../../hooks/useLeadGenerationApi';
import { reducerCalendarApi } from '../../hooks/useCalendarApi';
import { reducerApiHealthCheck } from '../../hooks/useApiHealthCheck';
import { reducerDSUserInfo } from '../../hooks/useDSUserInfo';
import { reducerFetchTinApi } from '../../hooks/useFetchTinApi';
import { reducerPostTinApi } from '../../hooks/usePostTinApi';
import { reducerPatchTinApi } from '../../hooks/usePatchTinApi';
import { reducerHolidayApi } from '../../hooks/useHolidayApi';
import { reducerSaveAsDraftApi } from '../../hooks/useSaveAsDraftApi';
import { reducerGetCommunicationAPI } from '../../hooks/useGetCommunicationAPI';
import { reducerGetDraftApi } from '../../hooks/useGetDraftApi';
import { reducerGetSentCommunicationApi } from '../../hooks/useGetSentCommunicationApi';
import { reducerDeleteCommunicationAPI } from '../../hooks/useDeleteCommunicationAPI';
import { reducerDeleteFile } from '../../hooks/useDeleteFile';
import { reducerDownloadFile } from '../../hooks/useDownloadFile';
import { reducerUploadFile } from '../../hooks/useUploadFile';
import { reducerSendCommunicationApi } from '../../hooks/useSendCommunicationApi/reducerSendCommunicationApi';
import { reducerGetUserAcceptanceTOU } from '../../hooks/useGetUserAcceptanceTOU';
import { reducerPostUserAcceptanceTOU } from '../../hooks/usePostUserAcceptanceTOU/reducerPostUserAcceptanceTOU';

export const reducers = {
  ...reducerUserStatus,
  ...reducerUserInfo,
  ...reducerGitHubUser,
  ...reducerGitHubRepos,
  ...reducerBreadcrumbs,
  ...reducerClaimSummary,
  ...reducerDocumentation,
  ...reducerRegenerateSecret,
  ...reducerFeedbackSubmission,
  ...reducerLeadGenerationApi,
  ...reducerCalendarApi,
  ...reducerApiHealthCheck,
  ...reducerDSUserInfo,
  ...reducerFetchTinApi,
  ...reducerPostTinApi,
  ...reducerPatchTinApi,
  ...reducerHolidayApi,
  ...reducerSaveAsDraftApi,
  ...reducerGetCommunicationAPI,
  ...reducerGetDraftApi,
  ...reducerGetSentCommunicationApi,
  ...reducerDeleteCommunicationAPI,
  ...reducerDeleteFile,
  ...reducerDownloadFile,
  ...reducerUploadFile,
  ...reducerSendCommunicationApi,
  ...reducerGetUserAcceptanceTOU,
  ...reducerPostUserAcceptanceTOU,
};
