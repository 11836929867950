import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { PageLayout } from 'src/common/PageLayout';
import { StyledLink } from 'src/common/Link';
import { Button } from '@abyss/web/ui/Button';
import { Breadcrumbs } from 'src/app/App/Breadcrumbs/Breadcrumbs';
import { Testimonials } from 'src/common/Testimonials/Testimonials';
import { testimonialMock } from 'src/app/App/__mock_data__/testimonialMock';
import { relatedAPIsMock } from 'src/app/App/__mock_data__/ClaimStatusX12API_relatedAPIs';
import { event } from '@abyss/web/tools/event';
import { APISlider } from 'src/common/APISlider/APISlider';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { styled } from '@abyss/web/tools/styled';
import { IconBrand } from '@abyss/web/ui/IconBrand';
import { config } from '@abyss/ui/tools/config';
import { SubscribeModal } from '../SubscribeModal';
import { SubscribeToAPIModal } from '../../SubscribeToAPIModal';
import { useDSUserInfo } from '../../../../../hooks/useDSUserInfo';
import { constants } from '../../../../../common/Utils/Constants';

const Wrapper = styled('div', {
  '@screen >= $xs': {
    padding: '16px',
  },

  '@screen >= $xl': {
    padding: '32px 0 0 0',
  },
});

const StyledFlex = styled('div', {
  display: 'flex',
  flexBasis: '75%',
});

const Container = styled('div', {
  flexBasis: '100%',
});

const PageHeadingContainer = styled('div', {
  display: 'flex',
  marginTop: '8px',
  marginBottom: '32px',
  flexBasis: '50%',
});

const Icon = styled('div', {
  height: '49.5px',
  width: '60px',

  '@screen >= $xs': {
    display: 'none',
  },

  '@screen >= $sm': {
    display: 'block',
  },
});

const PageHeading = styled('h1', {
  color: 'rgb(0, 38, 119)',
  fontSize: '36px',
  fontWeight: '600',
  minHeight: '40px',
  lineHeight: '40px',
  marginLeft: '12px',
});

const PageSubHeading = styled('h2', {
  color: 'rgb(0, 38, 119)',
  fontSize: '28px !important',
  fontWeight: '600',
  minHeight: '32px',
  lineHeight: '32px',
  marginBottom: '16px',
});

const PageSecondaySubHeading = styled('h4', {
  color: `${constants.FONT_COLOR_GREY} !important`,
  fontSize: '23px !important',
  fontWeight: 'bold',
  lineHeight: '24px',
  marginBottom: '16px',
  marginTop: '16px',
});

const PageLinkContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  margin: '0',
  marginTop: '32px',
  marginBottom: '0px',
});

const PageDesc = styled('div', {
  color: `${constants.FONT_COLOR_GREY} !important`,
  fontSize: '16px',
  fontWeight: 400,
  letterSpacing: '0px',
  lineHeight: '24px',
  marginBottom: '16px',
});

const PageContent = styled('div', {
  color: `${constants.FONT_COLOR_GREY} !important`,
  fontSize: '16px',
  fontWeight: 400,
  minHeight: '275px',
  letterSpacing: '0px',
  lineHeight: '24px',
  marginBottom: '48px',
});

const UL = styled('ul', {
  margin: '32px 0px 32px 48px',
  listStyle: 'disc',
});

const LI = styled('li', {
  margin: '8px',
  fontWeight: 'normal',
});

const AnchorBox = styled('div', {
  height: '20px',
  display: 'flex',
  alignContent: 'center',
  marginBottom: '16px',
  marginTop: '8px',
  marginLeft: '8px',
});

const ButtonMargin = styled('div', {
  margin: '24px 0',

  '@screen >= $xs': {
    button: {
      width: '100%',
    },
  },

  '@screen >= $sm': {
    button: {
      width: '339px',
    },
  },

  '@screen >= $xl': {
    textAlign: 'right',
  },
});

const Flexed = styled('div', {
  '@screen >= $xs': {
    display: 'block',
  },

  '@screen >= $xl': {
    display: 'flex',
  },
});

const ContainerHalf = styled('div', {
  flexBasis: '50%',
});

const LinkMargin = styled('div', {
  marginRight: '12px',
  alignSelf: 'center',
});

export const ClaimStatusX12API = () => {
  const [isSubscribeModalOpen, setIsSubscribeModalOpen] = useState(false);
  const [showSubscribeAPIModal, setShowSubscribeAPIModal] = useState(false);
  const userInfo = useSelector(state => state.GET_USER_INFO.data);
  const dsUserInfo = useSelector(state => state.GET_DS_USER_INFO);
  const [, getDSUserInfo] = useDSUserInfo();

  useEffect(() => {
    if (userInfo.uuid && !dsUserInfo?.data?.status) {
      getDSUserInfo({ userInfo });
    }
  }, [userInfo]);

  const subscribeToAPI = () => {
    const interactionName = 'claim status (276/277) extended x12 api';
    const interactionValue = constants.SUBSCRIBE_TO_API;
    const interactionContext = 'page cta link clicks';
    event('INTERACTION_EVENT', {
      interactionName,
      interactionValue,
      interactionContext,
    });
  };

  const subscribeClicked = () => {
    const jobName = constants.SUB_TO_API_JOB_NAME;

    subscribeToAPI();

    if (userInfo.firstName === '' || userInfo.error) {
      setIsSubscribeModalOpen(true);
    } else {
      event('SUBSCRIBE_EVENT', {
        event,
        jobName,
      });

      setShowSubscribeAPIModal(true);
    }
  };
  useEffect(() => {
    event('API_DETAILS_CLAIMSTATUSX12API_PAGE_LOAD', {
      dsUserInfo: dsUserInfo.data,
      userInfo,
    });
  }, []);

  const openPdf = () => {
    const fileName = 'api technical documentation';
    const fileURL = config('CLAIMS_EXTENDED_GUIDE');
    const fileContext = 'pdf guide';

    event('DOWNLOAD_CLICK', {
      fileName,
      fileURL,
      fileContext,
    });
    window.open(config('CLAIMS_EXTENDED_GUIDE'));
  };

  return (
    <PageLayout>
      <SubscribeModal
        setIsSubscribeModalOpen={setIsSubscribeModalOpen}
        isSubscribeModalOpen={isSubscribeModalOpen}
      />
      <SubscribeToAPIModal
        showModal={showSubscribeAPIModal}
        modalOnClose={setShowSubscribeAPIModal}
      />
      <Wrapper>
        <StyledFlex>
          <Container>
            <Breadcrumbs />
            <Flexed>
              <PageHeadingContainer>
                <Icon>
                  <IconBrand icon="clipboard" size={48} />
                </Icon>
                <PageHeading>
                  Claim Status (276/277) Extended X12 API
                </PageHeading>
              </PageHeadingContainer>
              <ContainerHalf>
                <Container>
                  <ButtonMargin>
                    <Button
                      variant="solid"
                      onClick={openPdf}
                      css={{
                        backgroundColor: '#002677',
                        fontSize: '15px',
                        height: '42px',

                        '@screen >= $xs': {
                          width: '100%',
                        },

                        '@media (min-width: 576px)': {
                          width: '339px',
                        },
                      }}
                    >
                      API Technical Documentation
                    </Button>
                  </ButtonMargin>
                </Container>
              </ContainerHalf>
            </Flexed>
            <PageSubHeading>Overview</PageSubHeading>
            <PageDesc>
              Use the <b>Claim Status Inquiry (276)</b> transaction to inquire
              about the status of a claim after it has been sent to a payer,
              whether submitted on paper or electronically.
            </PageDesc>
            <PageDesc>
              The <b>Claim Status Response (277)</b> transaction is used to
              respond to a request inquiry about the status of a claim after it
              has been sent to a payer, whether submitted on paper or
              electronically.
            </PageDesc>
            <PageContent>
              Electronic claim status transactions may have the following
              benefits:
              <UL>
                <LI> Increased productivity and efficiency</LI>
                <LI> Less time spent on manual, administrative tasks</LI>
                <LI> Decreased duplicate claim submissions</LI>
                <LI> Improved cash flow</LI>
                <LI>
                  Available for participating and non-participating health care
                  professionals
                </LI>
              </UL>
              <PageLinkContainer>
                <AnchorBox>
                  <LinkMargin>
                    <StyledLink
                      fontWeight="$bold"
                      id="SubscribeToAPI"
                      onClick={subscribeClicked}
                      after={<IconMaterial icon="arrow_forward" size="20px" />}
                    >
                      {constants.SUBSCRIBE_TO_API}
                    </StyledLink>
                  </LinkMargin>
                </AnchorBox>
              </PageLinkContainer>
              <PageSecondaySubHeading>
                About Claim Status (276/277) Extended X12 API
              </PageSecondaySubHeading>
              <PageDesc>
                Once an acknowledgment that a claim has been accepted is
                returned, it should be available for query as a claim status
                search. Physicians and other health care professionals can
                perform claim status (276/277) transactions in batch or
                real-time mode, based on their connectivity method.
              </PageDesc>
              <PageDesc>
                API data is returned as an extension to your existing compliant
                EDI transaction responses. The extended data provides
                information that is not included in the standard EDI response,
                which eliminates billing staff having to call us or going to the
                UnitedHealthcare Provider Portal to research.­ ­
              </PageDesc>
              <PageDesc>
                For more on API Extended X12, email supportedi@uhc.com or call
                800-842-1109.­
              </PageDesc>
            </PageContent>
          </Container>
        </StyledFlex>
        <Testimonials initialData={testimonialMock} />
        <APISlider initialData={relatedAPIsMock} />
      </Wrapper>
    </PageLayout>
  );
};
