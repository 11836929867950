import { Modal } from '@abyss/web/ui/Modal';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from '@abyss/web/ui/Checkbox';
import { Button } from '@abyss/web/ui/Button';
import { Link } from '@abyss/web/ui/Link';
import { Text } from '@abyss/web/ui/Text';
import { config } from '@abyss/ui/tools/config';
import { useSelector } from 'react-redux';
import { useLagoon } from '@abyss/web/hooks/useLagoon';
import { usePostUserAcceptanceTOU } from '../../../../../hooks/usePostUserAcceptanceTOU/usePostUserAcceptanceTOU';

export const TouModal = ({ showTouModal, setShowTouModal }) => {
  const userInfo = useSelector(state => state.GET_USER_INFO.data);
  const [isChecked, setChecked] = useState(false);
  const [, postUserAcceptance] = usePostUserAcceptanceTOU();
  const clickHandler = () => {
    postUserAcceptance({ domain: 'UHCP-Portal', accepted: true, userInfo });
    setShowTouModal(false);
  };
  const configs = useLagoon('configs');

  const checkBoxLabel = () => {
    return (
      <Text style={{ fontSize: '15px' }}>
        By selecting this checkbox, I have reviewed and agree to the{' '}
        <Link
          href={
            configs('TERMS_OF_USE_LINK')?.value || config('TERMS_OF_USE_LINK')
          }
          hideIcon
          style={{ fontSize: '15px' }}
        >
          Terms of Use
        </Link>{' '}
        and{' '}
        <Link
          href={configs('PRIVACY_LINK')?.value || config('PRIVACY_LINK')}
          hideIcon
          style={{ fontSize: '15px' }}
        >
          Privacy Policy.
        </Link>
      </Text>
    );
  };

  return (
    <Modal
      title="Terms of Use and Privacy Policy"
      isOpen={showTouModal}
      closeOnClickOutside={false}
      hideClose
    >
      <Modal.Section style={{ fontSize: '15px !important' }}>
        <Text style={{ fontSize: '15px' }}>
          Welcome! Thanks for using our site and applications.
        </Text>
      </Modal.Section>
      <Modal.Section>
        <Text style={{ fontSize: '15px' }}>
          Please review our Terms of Use and Privacy Policy. You will then be
          routed to our site after selecting the check box below and clicking
          the Accept button.
        </Text>
      </Modal.Section>
      <Modal.Section>
        <Checkbox
          label={checkBoxLabel()}
          isChecked={isChecked}
          onChange={() => {
            setChecked(check => !check);
          }}
        />
      </Modal.Section>
      <Modal.Section>
        <Button
          isDisabled={!isChecked}
          onClick={() => clickHandler()}
          style={{ fontSize: '15px' }}
        >
          Accept
        </Button>
      </Modal.Section>
    </Modal>
  );
};

TouModal.propTypes = {
  showTouModal: PropTypes.bool.isRequired,
  setShowTouModal: PropTypes.func.isRequired,
};
