import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { config } from '@abyss/ui/tools/config';
import { event } from '@abyss/web/tools/event';
import { getUserInfoDataForEAPM } from '../../tools/utils/utils';

const useAction = actionFn => {
  const dispatch = useDispatch();
  return useCallback(
    (...args) => {
      return dispatch(actionFn(...args));
    },
    [dispatch]
  );
};

export const ACTION_TYPE = 'GET_USER_ACCEPTANCE_TOU';

export const useGetUserAcceptanceTOU = () => {
  const result = useSelector(state => state[ACTION_TYPE]);

  const action = useAction(args => {
    const eapm = window.elasticApm;
    let transaction;
    let status;
    let statusText;
    if (eapm) {
      transaction = eapm.startTransaction(ACTION_TYPE, 'http-request', {
        managed: false,
      });
    }
    return dispatch => {
      dispatch({ type: `${ACTION_TYPE}_CALLED` });
      fetch(`${config('DS_NEW_PROXY_URL')}${config('USER_ACCEPTANCE_TOU')}`)
        .then(res => {
          status = res.status;
          statusText = res.statusText;
          event('API_TRANSACTION', {
            fields: {
              appVersion: process.env.PACKAGE_VERSION,
              url: window.location.href,
              endpoint: config('USER_ACCEPTANCE_TOU'),
              method: 'GET',
              userInfo: args?.userInfo,
              code: res.status,
              operationName: ACTION_TYPE,
              message: res.statusText,
              // result: hasReportCode ? 'success' : 'error',
            },
          });
          return res.json();
        })
        .then(res => {
          dispatch({
            type: `${ACTION_TYPE}_COMPLETED`,
            payload: res,
          });
        })
        .catch(error => {
          dispatch({
            type: `${ACTION_TYPE}_ERROR`,
            payload: error,
          });
          event('API_TRANSACTION_ERROR', {
            fields: {
              appVersion: process.env.PACKAGE_VERSION,
              url: window.location.href,
              endpoint: config('USER_ACCEPTANCE_TOU'),
              method: 'GET',
              userInfo: args?.userInfo,
              operationName: ACTION_TYPE,
            },
          });
        })
        .finally(() => {
          const outcome = status && status === 200 ? 'success' : 'error';

          if (transaction) {
            transaction.addLabels({
              result: outcome,
              appVersion: process.env.PACKAGE_VERSION,
              url: window.location.href,
              endpoint: config('USER_ACCEPTANCE_TOU'),
              method: 'GET',
              code: status,
              message: statusText,
              ...getUserInfoDataForEAPM(args?.userInfo),
              operationName: ACTION_TYPE,
            });

            transaction.end({ outcome });
          }
        });
    };
  });

  return [result, action];
};
