import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { PageLayout } from 'src/common/PageLayout';
import { StyledLink } from 'src/common/Link';
import { Breadcrumbs } from 'src/app/App/Breadcrumbs/Breadcrumbs';
import { event } from '@abyss/web/tools/event';
import { Button } from '@abyss/web/ui/Button';
import { Testimonials } from 'src/common/Testimonials/Testimonials';
import { testimonialMock } from 'src/app/App/__mock_data__/testimonialMock';
import { APISlider } from 'src/common/APISlider/APISlider';
import { relatedAPIsMock } from 'src/app/App/__mock_data__/eligibilityAndBenefits_relatedAPIs';
import { styled } from '@abyss/web/tools/styled';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { IconBrand } from '@abyss/web/ui/IconBrand';
import { config } from '@abyss/ui/tools/config';
import { SubscribeModal } from '../SubscribeModal';
import { SubscribeToAPIModal } from '../../SubscribeToAPIModal';
import { useDSUserInfo } from '../../../../../hooks/useDSUserInfo';
import { constants } from '../../../../../common/Utils/Constants';

const Wrapper = styled('div', {
  '@screen >= $xs': {
    padding: '16px',
  },

  '@screen >= $xl': {
    padding: '32px 0 0 0',
  },
});

const StyledFlex = styled('div', {
  display: 'flex',
  flexBasis: '75%',
});

const Container = styled('div', {
  flexBasis: '100%',
});

const Flexed = styled('div', {
  '@screen >= $xs': {
    display: 'block',
  },

  '@screen >= $xl': {
    display: 'flex',
  },
});

const PageHeadingContainer = styled('div', {
  display: 'flex',
  marginTop: '8px',
  marginBottom: '32px',
  flexBasis: '50%',
});

const StyledIcon = styled('div', {
  height: '49.5px',
  width: '60px',

  '@screen >= $xs': {
    display: 'none',
  },

  '@screen >= $sm': {
    display: 'block',
  },
});

const PageHeading = styled('h1', {
  color: 'rgb(0, 38, 119)',
  fontSize: '36px',
  fontWeight: 600,
  minHeight: '40px',
  lineHeight: '40px',
  marginLeft: '12px',
});

const ContainerHalf = styled('div', {
  flexBasis: '50%',
});

const ButtonMargin = styled('div', {
  margin: '24px 0px',
  textAlign: 'right',

  '@screen >= $sm': {
    textAlign: 'left',
  },

  '@screen >= $xl': {
    textAlign: 'right',
  },
});

const PageSubHeading = styled('h2', {
  color: 'rgb(0, 38, 119)',
  fontSize: '28px !important',
  fontWeight: 600,
  minHeight: '32px',
  lineHeight: '32px',
  marginBottom: '16px',
});

const PageDesc = styled('div', {
  color: `${constants.FONT_COLOR_GREY} !important`,
  fontSize: '16px',
  fontWeight: 400,
  letterSpacing: '0px',
  lineHeight: '24px',
  marginBottom: '16px',
});

const UL = styled('ul', {
  margin: '32px 0px 32px 48px',
  listStyle: 'disc',
});

const LI = styled('li', {
  margin: '8px',
  fontWeight: 'normal',
});

const PageLinkContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  margin: 0,
  marginTop: '32px',
  marginBottom: '0px',
});

const AnchorBox = styled('div', {
  height: '20px',
  display: 'flex',
  marginBottom: '16px',
  marginTop: '8px',
  marginLeft: '8px',
});

const LinkMargin = styled('div', {
  marginRight: '12px',
  alignSelf: 'center',
});

const PageSecondaySubHeading = styled('h4', {
  color: `${constants.FONT_COLOR_GREY} !important`,
  fontSize: '23px !important',
  fontWeight: 'bold',
  lineHeight: '24px',
  marginTop: '16px',
  marginBottom: '16px',
});

const PageContent = styled('div', {
  color: `${constants.FONT_COLOR_GREY} !important`,
  fontSize: '16px',
  fontWeight: 400,
  letterSpacing: '0px',
  lineHeight: '24px',
  marginBottom: '48px',
});

const GapHeight = styled('div', {
  height: '32px',
});

export const EligibilityAndBenefits = () => {
  const [isSubscribeModalOpen, setIsSubscribeModalOpen] = useState(false);
  const [showSubscribeAPIModal, setShowSubscribeAPIModal] = useState(false);
  const userInfo = useSelector(state => state.GET_USER_INFO.data);
  const dsUserInfo = useSelector(state => state.GET_DS_USER_INFO);
  const [, getDSUserInfo] = useDSUserInfo();

  useEffect(() => {
    if (userInfo.uuid && !dsUserInfo.data.status) {
      getDSUserInfo({ userInfo });
    }
  }, [userInfo]);

  const subscribeToAPI = () => {
    const interactionName = `Eligibility And Benefits (270/271) Extended X12 API`;
    const interactionValue = constants.SUBSCRIBE_TO_API;
    const interactionContext = 'page cta link clicks';
    event('INTERACTION_EVENT', {
      interactionName,
      interactionValue,
      interactionContext,
    });
  };

  const subscribeClicked = () => {
    const jobName = constants.SUB_TO_API_JOB_NAME;

    subscribeToAPI();

    if (userInfo.firstName === '' || userInfo.error) {
      setIsSubscribeModalOpen(true);
    } else {
      event('SUBSCRIBE_EVENT', {
        event,
        jobName,
      });
      setShowSubscribeAPIModal(true);
    }
  };
  const openPdf = () => {
    const fileName = 'api technical documentation';
    const fileURL = config('ELIGIBILITY_EXTENDED_GUIDE');
    const fileContext = 'pdf guide';

    event('DOWNLOAD_CLICK', {
      fileName,
      fileURL,
      fileContext,
    });

    window.open(config('ELIGIBILITY_EXTENDED_GUIDE'));
  };

  useEffect(() => {
    event('API_DETAILS_ELIGIBILITYX12API_PAGE_LOAD', {
      dsUserInfo: dsUserInfo.data,
      userInfo,
    });
  }, []);

  return (
    <PageLayout>
      <SubscribeModal
        setIsSubscribeModalOpen={setIsSubscribeModalOpen}
        isSubscribeModalOpen={isSubscribeModalOpen}
      />
      <SubscribeToAPIModal
        showModal={showSubscribeAPIModal}
        modalOnClose={setShowSubscribeAPIModal}
      />
      <Wrapper>
        <StyledFlex>
          <Container>
            <Breadcrumbs />
            <Flexed>
              <PageHeadingContainer>
                <StyledIcon>
                  <IconBrand icon="id_card" size={48} />
                </StyledIcon>
                <PageHeading>
                  Eligibility and Benefits (270/271) Extended X12 API
                </PageHeading>
              </PageHeadingContainer>
              <ContainerHalf>
                <ButtonMargin>
                  <Button
                    variant="solid"
                    onClick={openPdf}
                    css={{
                      backgroundColor: '#002677',
                      fontSize: '15px',
                      height: '42px',

                      '@screen >= $xs': {
                        width: '100%',
                      },

                      '@media (min-width: 576px)': {
                        width: '339px',
                      },
                    }}
                  >
                    API Technical Documentation
                  </Button>
                </ButtonMargin>
              </ContainerHalf>
            </Flexed>
            <PageSubHeading>Overview</PageSubHeading>
            <PageDesc>
              Use the Eligibility and Benefit Inquiry (270) transaction to
              inquire about the health care eligibility and benefits associated
              with a subscriber or dependent.
              <br />
              The Eligibility and Benefit Response (271) transaction is used to
              respond to a request inquiry about the health care eligibility and
              benefits associated with a subscriber or dependent.
              <GapHeight />
              Electronic eligibility verification may result in the following
              benefits:
              <UL>
                <LI>Reduced collection and billing costs</LI>
                <LI> Decreased bad debt </LI>
                <LI> Improved cash flow </LI>
                <LI> Increased productivity and efficiency </LI>
                <LI> Fewer rejected claims </LI>
                <LI> Less time spent on manual, administrative tasks </LI>
                <LI> Expedited reimbursement </LI>
                <LI>
                  {' '}
                  Available to participating and non-participating health care
                  professionals{' '}
                </LI>
              </UL>
            </PageDesc>
            <PageLinkContainer>
              <AnchorBox>
                <LinkMargin>
                  <StyledLink
                    id="SubscribeToAPI"
                    onClick={subscribeClicked}
                    after={<IconMaterial icon="arrow_forward" size="20px" />}
                    fontWeight="$bold"
                  >
                    {constants.SUBSCRIBE_TO_API}
                  </StyledLink>
                </LinkMargin>
              </AnchorBox>
            </PageLinkContainer>
            <PageSecondaySubHeading>
              About Eligibility and Benefits (270/271) Extended X12 API
            </PageSecondaySubHeading>
            <PageContent>
              <UL>
                <LI>
                  You can obtain detailed benefit information including member
                  ID number, date of coverage, copayment, year-to-date
                  deductible amount, and commercial coordination of benefit
                  (COB) information when applicable.
                </LI>
                <LI>
                  Physicians and other health care professionals can perform
                  eligibility (270/271) transactions in batch or real-time mode,
                  based on your connectivity method.
                </LI>
                <LI>
                  API data is returned as an extension to your existing
                  compliant EDI transaction responses. The extended data
                  provides information that is not included in the standard EDI
                  response, which eliminates billing staff having to call us or
                  going to the UnitedHealthcare Provider Portal to research.­
                </LI>
                <LI>
                  For more on API Extended X12, email supportedi@uhc.com or call
                  800-842-1109.­
                </LI>
              </UL>
            </PageContent>
          </Container>
        </StyledFlex>
        <Testimonials initialData={testimonialMock} />
        <APISlider initialData={relatedAPIsMock} />
      </Wrapper>
    </PageLayout>
  );
};
